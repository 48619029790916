import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './GalleryGrid.scss';
import { Image } from '~/components/atoms';
import ImageSource from '~/components/templates/common/ImageSource';
import { ImageInterface } from '~/components/atoms/image/Image';

interface GalleryGridItemInterface {
  image: ImageInterface;
  title: string;
  eager?: boolean;
  imageSource?: string;
}

@Component({
  style,
})
export default class GalleryGridItem
  extends VueComponent<GalleryGridItemInterface>
  implements GalleryGridItemInterface {
  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop()
  public eager?: boolean;

  @Prop()
  public imageSource?: string;

  public render() {
    return (
      <v-carousel-item eager={this.eager}>
        <v-row no-gutters class='fill-height' align='center' justify='center'>
          <Image
            alt={this.title}
            src={this.image.src}
            srcset={this.image.srcset}
            sizes='100vw'
          />
          {this.imageSource && <ImageSource imageSource={this.imageSource} />}
        </v-row>
      </v-carousel-item>
    );
  }
}
